.container-main {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.home-desktop {
  cursor: default;
  background-color: #fff;
  position: relative;
}

.home-desktop .overlap {
  width: 100%;
}

.home-desktop .union {
  width: 375px;
  height: 991px;
  position: absolute;
  top: 2051px;
  left: 0;
}

.home-desktop .rectangle {
  background: linear-gradient(#fff 0%, #fefefe00 100%);
  width: 375px;
  height: 215px;
  position: absolute;
  top: 1754px;
  left: 0;
  transform: rotate(180deg);
}

.home-desktop .div {
  background: linear-gradient(#fff 0%, #fefefe00 100%);
  width: 375px;
  height: 215px;
  position: absolute;
  top: 6740px;
  left: 0;
  transform: rotate(180deg);
}

.home-desktop .frame {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.home-desktop .content {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 12px 8px 12px 16px;
  display: flex;
  position: relative;
}

.home-desktop .frame-2 {
  position: relative;
}

.home-desktop .overlap-group {
  width: 59px;
  height: 19px;
  position: absolute;
  top: 6px;
  left: 35px;
}

.home-desktop .text-wrapper {
  color: #000;
  letter-spacing: 0;
  font-family: Encode Sans SC, Helvetica;
  font-size: 4.5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: -4.5px;
  left: 54px;
}

.home-desktop .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Encode Sans SC, Helvetica;
  font-size: 19.7px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .img-2 {
  cursor: pointer;
  z-index: 1000;
  width: 24px;
  height: 24px;
  position: relative;
}

.home-desktop .section {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .overlap-group-wrapper {
  width: 100%;
  position: relative;
}

.home-desktop .overlap-group-2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 517px;
  display: flex;
  position: relative;
}

.home-desktop .union-2 {
  width: 365px;
  height: 283px;
  position: absolute;
  top: 128px;
}

.home-desktop .untitled {
  width: 375px;
  height: 517px;
  position: absolute;
  top: 0;
}

.home-desktop .union-3 {
  width: 369px;
  height: 284px;
  position: absolute;
  top: 118px;
}

.home-desktop .container {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 13.51px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .title {
  text-align: center;
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -.56px;
  position: relative;
}

.home-desktop .p {
  text-align: center;
  color: #000;
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  position: relative;
}

.home-desktop .button {
  flex: none;
  align-items: flex-start;
  gap: 11.86px;
  margin-right: -1px;
  display: inline-flex;
  position: relative;
}

@media only screen and (width <= 350px) {
  .home-desktop .button {
    flex-direction: column;
    flex: none;
    align-items: center;
    gap: 11.86px;
    margin-right: -1px;
    display: flex;
    position: relative;
  }
}

.home-desktop .primary {
  background-color: #d6e84d;
  border-radius: 7.9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 158.07px;
  padding: 11.86px;
  display: flex;
  position: relative;
}

.home-desktop .title-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.99px;
  font-family: Roboto, Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  line-height: 23.7px;
  position: relative;
}

.home-desktop .seconday {
  border: .99px solid;
  border-color: var(--brandbrand-3);
  border-radius: 7.9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 158.07px;
  padding: 11.86px;
  display: flex;
  position: relative;
}

.home-desktop .section-2 {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 32px 0 60px;
  display: flex;
  position: relative;
}

.home-desktop .frame-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 328px;
  margin-left: -.5px;
  margin-right: -.5px;
  display: flex;
  position: relative;
}

.home-desktop .airbnb-search {
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.home-desktop .attribute {
  flex-direction: column;
  align-items: flex-start;
  width: 193px;
  display: flex;
  position: relative;
}

@media only screen and (width <= 1440px) {
  .home-desktop .attribute {
    width: 100%;
    max-width: 193px;
  }
}

.home-desktop .attribute-2 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  width: 162px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .value {
  color: #7d7d7d;
  font-family: var(--desktop-medium-h7-font-family);
  font-size: var(--desktop-medium-h7-font-size);
  font-style: var(--desktop-medium-h7-font-style);
  font-weight: var(--desktop-medium-h7-font-weight);
  letter-spacing: var(--desktop-medium-h7-letter-spacing);
  line-height: var(--desktop-medium-h7-line-height);
  width: fit-content;
  position: relative;
}

.home-desktop .airbnb-search-2 {
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.home-desktop .primary-2 {
  background-color: #d6e84d;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .title-3 {
  color: #000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  width: fit-content;
  position: relative;
}

.home-desktop .frame-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  margin-left: -8.5px;
  margin-right: -8.5px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.home-desktop .list {
  background-color: #fff;
  border-radius: 21px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 19px;
  width: 100%;
  display: flex;
  position: relative;
}

@media only screen and (width <= 480px) {
  .home-desktop .list {
    padding: 0 16px;
  }
}

.home-desktop .row {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .card {
  border: 1px solid #0000001a;
  border-radius: 6px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.home-desktop .image-container {
  align-items: flex-start;
  height: 150px;
  display: flex;
  overflow: hidden;
}

.home-desktop .image {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.home-desktop .title-wrapper {
  background-color: #d4e64d;
  border-radius: 17px;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 10px;
  display: inline-flex;
  position: absolute;
  top: 13px;
  right: 11px;
}

.home-desktop .title-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home-desktop .text-content {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9px;
  width: 100%;
  padding: 8px 12px;
  display: flex;
  position: relative;
}

.home-desktop .subtitle {
  color: #000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .user {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .avatar {
  flex: 1;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.home-desktop .avatar-2 {
  background-position: 50%;
  background-size: cover;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  position: relative;
}

.home-desktop .div-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 40%;
  display: flex;
  position: relative;
}

.home-desktop .title-5 {
  -webkit-line-clamp: 1;
  color: #000;
  letter-spacing: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 16px;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .frame-5 {
  width: 58.26px;
  height: 9.81px;
  position: relative;
}

.home-desktop .frame-6 {
  opacity: .5;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .img-3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.home-desktop .title-6 {
  color: #000;
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home-desktop .image-wrapper {
  align-items: flex-start;
  height: 150px;
  display: flex;
}

.home-desktop .frame-wrapper {
  background-image: url("image-6.84ac5055.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .image-container-2 {
  background-image: url("image-container-1.3bfe97a3.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  display: flex;
  position: relative;
}

.home-desktop .image-2 {
  background-image: url("image-7.ffca80a7.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-7 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 35px;
  width: 100%;
  padding: 32px 0;
  display: flex;
  position: relative;
}

.home-desktop .frame-8 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.home-desktop .title-7 {
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .subtitle-2 {
  color: #000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  text-align: center;
  width: 314px;
  position: relative;
}

.home-desktop .frame-9 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.home-desktop .item {
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 10px;
  align-items: flex-start;
  gap: 20px;
  max-width: 450px;
  padding: 24px 30px;
  display: flex;
  position: relative;
}

.home-desktop .vuesax-linear-wrapper {
  background-color: var(--brandbrand-3);
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .frame-10 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
  position: relative;
}

.home-desktop .title-8 {
  color: #000;
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .item-2 {
  background-color: #fff;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  align-items: flex-start;
  gap: 20px;
  max-width: 450px;
  padding: 24px 30px;
  display: flex;
  position: relative;
}

.home-desktop .img-wrapper {
  background-color: var(--grey-grey-3, #e5e5ea);
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .subtitle-3 {
  color: var(--greygrey-2);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-11 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 100%;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.home-desktop .div-2 {
  flex-direction: column;
  flex: none;
  place-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .article {
  border-radius: 6px;
  flex: none;
  justify-content: center;
  gap: 16px;
  max-width: 450px;
  padding: 16px;
  display: flex;
  position: relative;
}

.home-desktop .text-wrapper-3 {
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .vector {
  width: 3px;
  height: 83px;
  position: relative;
}

.home-desktop .frame-12 {
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 28px;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 6px 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .title-9 {
  color: #000;
  font-family: var(--mobile-extrabold-h6-font-family);
  font-size: var(--mobile-extrabold-h6-font-size);
  font-style: var(--mobile-extrabold-h6-font-style);
  font-weight: var(--mobile-extrabold-h6-font-weight);
  letter-spacing: var(--mobile-extrabold-h6-letter-spacing);
  line-height: var(--mobile-extrabold-h6-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .title-10 {
  color: #000;
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .subtitle-4 {
  color: #000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  width: 224px;
  position: relative;
}

.home-desktop .subtitle-5 {
  color: #000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  width: 222px;
  position: relative;
}

.home-desktop .frame-13 {
  background-color: var(--Grey-Grey--5, #1c1c1e);
  border: 1px solid;
  border-color: var(--Grey-Grey--5, #1c1c1e);
  border-radius: 28px;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 6px 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .title-11 {
  color: var(--grey-grey-5, #fff);
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .title-11-black {
  color: #000;
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .frame-14 {
  background-color: var(--grey-grey-3, #e5e5ea);
  border-radius: 28px;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 6px 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .rectangle-2 {
  width: 100%;
  position: relative;
}

.home-desktop .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  width: 100%;
  margin-left: -9.12px;
  margin-right: -9.12px;
  padding: 32px 20px;
  display: inline-flex;
  position: relative;
}

.home-desktop .title-12 {
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  text-align: center;
  margin-top: -1px;
  position: relative;
}

.home-desktop .union-4 {
  object-fit: cover;
  width: 100%;
  position: relative;
}

.home-desktop .frame-16 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.home-desktop .frame-17 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 24px 29px;
  display: flex;
  position: relative;
}

.home-desktop .featured-icon {
  background-color: #d6e84d;
  border: 8px solid #d6e84d8f;
  border-radius: 28px;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-top: -4px;
  margin-left: -4px;
  display: flex;
  position: relative;
}

.home-desktop .img-4 {
  height: 24px;
  left: 12px;
}

.home-desktop .div-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .title-13 {
  color: var(--greygrey-5);
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .subtitle-6 {
  color: var(--greygrey-2);
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  width: 100%;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.home-desktop .title-14 {
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  text-align: center;
  margin-top: -1px;
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
}

.home-desktop .frame-19 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .card-2 {
  background-color: #fbfcef;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 152px;
  padding: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .avatar-3 {
  background-image: url("avatar-8.e6534957.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .title-15 {
  -webkit-line-clamp: 1;
  color: #000;
  letter-spacing: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 20px;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .title-16 {
  -webkit-line-clamp: 3;
  color: #000;
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 72px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .avatar-4 {
  background-image: url("avatar-9.74ce42d1.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-5 {
  background-image: url("avatar-10.2fe0ad7d.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-6 {
  background-image: url("avatar-11.431788b6.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-7 {
  background-image: url("avatar-12.0a0bd593.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .section-3 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 52px;
  width: 100%;
  height: 1228px;
  margin-left: -.5px;
  margin-right: -.5px;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.home-desktop .subtitle-7 {
  color: #000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-20 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .US-divided {
  width: 100%;
  position: relative;
}

.home-desktop .content-wrapper {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .form-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .div-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .form-fields {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .input-field {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .div-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .label {
  color: #58595c;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  background: none;
  border: none;
  width: fit-content;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .input {
  box-shadow: var(--shadow-xs);
  background-color: #f8f8f8;
  border: 1px solid #cecece;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .text {
  color: #565656;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  background: none;
  border: none;
  flex: 1;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .textarea-input-field {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 154px;
  display: flex;
  position: relative;
}

.home-desktop .div-6 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .label-2 {
  color: #58595c;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .input-2 {
  box-shadow: var(--shadow-xs);
  background-color: #f8f8f8;
  border: 1px solid #cecece;
  border-radius: 8px;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 14px;
  display: flex;
  position: relative;
}

.home-desktop .checkbox {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .checkbox-2 {
  box-shadow: var(--shadow-xs);
  background-color: #f9f9f9;
  border: 1px solid #474747;
  border-radius: 5px;
  align-items: center;
  gap: 8px;
  width: 20px;
  height: 20px;
  display: flex;
  position: relative;
}

.home-desktop .text-2 {
  color: gray;
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.home-desktop .span {
  color: gray;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
}

.home-desktop .text-wrapper-4 {
  cursor: pointer;
  text-decoration: underline;
}

.home-desktop .button-base-wrapper {
  all: unset;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .button-base {
  box-shadow: var(--shadow-xs);
  cursor: pointer;
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .text-3 {
  color: #000;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .section-4 {
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 410px;
  margin-left: -.5px;
  margin-right: -.5px;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.home-desktop .content-2 {
  background-color: #d6e84d;
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  display: flex;
  position: relative;
}

.home-desktop .heading {
  color: #000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .supporting-text {
  color: var(--greygrey-2);
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .input-3 {
  box-shadow: var(--shadow-xs);
  background-color: #fff;
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 12px 14px 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .content-3 {
  color: #6b6b6b;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  background: none;
  border: none;
  flex: 1;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .hint-text {
  color: #6b6b6b;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.home-desktop .text-wrapper-5 {
  color: #6b6b6b;
  font-family: var(--text-sm-normal-font-family);
  font-size: var(--text-sm-normal-font-size);
  font-style: var(--text-sm-normal-font-style);
  font-weight: var(--text-sm-normal-font-weight);
  letter-spacing: var(--text-sm-normal-letter-spacing);
  line-height: var(--text-sm-normal-line-height);
}

.home-desktop .button-base-2 {
  all: unset;
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  cursor: pointer;
  background-color: #000;
  border: 1px solid;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .text-4 {
  color: #fff;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .footer {
  background-color: #000;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin-left: -.5px;
  margin-right: -.5px;
  padding: 32px 24px;
  display: flex;
  position: relative;
}

.home-desktop .logo-and-supporting {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .frame-21 {
  width: 179.73px;
  height: 46.14px;
  position: relative;
}

.home-desktop .overlap-group-3 {
  width: 110px;
  height: 36px;
  position: absolute;
  top: 10px;
  left: 66px;
}

.home-desktop .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  font-family: Encode Sans SC, Helvetica;
  font-size: 8.4px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 35px;
  left: 101px;
}

.home-desktop .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Encode Sans SC, Helvetica;
  font-size: 36.9px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .union-5 {
  width: 60px;
  height: 47px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .supporting-text-2 {
  color: #828282;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .footer-links {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .footer-link {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.home-desktop .button-base-3 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .text-5 {
  color: #d6e84d;
  font-family: var(--mobile-extrabold-h5-font-family);
  font-size: var(--mobile-extrabold-h5-font-size);
  font-style: var(--mobile-extrabold-h5-font-style);
  font-weight: var(--mobile-extrabold-h5-font-weight);
  letter-spacing: var(--mobile-extrabold-h5-letter-spacing);
  line-height: var(--mobile-extrabold-h5-line-height);
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .text-6 {
  color: #828282;
  font-family: var(--mobile-extrabold-h5-font-family);
  font-size: var(--mobile-extrabold-h5-font-size);
  font-style: var(--mobile-extrabold-h5-font-style);
  font-weight: var(--mobile-extrabold-h5-font-weight);
  letter-spacing: var(--mobile-extrabold-h5-letter-spacing);
  line-height: var(--mobile-extrabold-h5-line-height);
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .button-wrapper {
  all: unset;
  box-sizing: border-box;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .button-base-4 {
  all: unset;
  box-sizing: border-box;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.home-desktop .button-3 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.home-desktop .social-icons {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.home-desktop .social-icon {
  background-image: url("subtract-1.115e2da2.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: relative;
}

.home-desktop .button-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .primary-3 {
  background-color: #d6e84d;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .title-17 {
  color: #000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .seconday-2 {
  border: 1px solid #fff;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .title-18 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.home-desktop .container-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px 0;
  display: flex;
  position: relative;
}

.home-desktop .divider {
  background-color: #282828;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.home-desktop .content-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .footer-text {
  color: #6f6f6f;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .div-7 {
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .footer-link-2 {
  color: #6f6f6f;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .top {
  width: 100%;
  height: 43px;
  position: fixed;
  top: 0;
  left: 0;
}

.home-desktop .overlap-wrapper {
  width: 100%;
  max-width: 1200px;
  height: 689px;
  margin: 0 auto;
  position: relative;
}

.home-desktop .overlap-2 {
  position: relative;
}

.home-desktop .overlap-3 {
  width: 100%;
}

.home-desktop .graphic-element {
  width: 633px;
  height: 756px;
  position: absolute;
  top: 52px;
  right: 0;
}

.home-desktop .overlap-group-4 {
  width: 605px;
  height: 722px;
  position: relative;
}

.home-desktop .union-6 {
  width: 513px;
  height: 396px;
  position: absolute;
  top: 179px;
  left: 69px;
}

.home-desktop .untitled-2 {
  width: 605px;
  height: 722px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .union-7 {
  width: 516px;
  height: 397px;
  position: absolute;
  top: 164px;
  left: 59px;
}

.home-desktop .top-bar {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 80px;
  margin: 0 auto;
  padding: 20px 16px;
  display: flex;
}

.home-desktop .frame-22 {
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-desktop .overlap-group-5 {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.home-desktop .text-wrapper-8 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  font-family: Encode Sans SC, Helvetica;
  font-size: 6.6px;
  font-weight: 400;
  line-height: normal;
}

.home-desktop .text-wrapper-9 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Encode Sans SC, Helvetica;
  font-size: 28.7px;
  font-weight: 700;
  line-height: normal;
}

.home-desktop .union-8 {
  width: 100%;
}

.home-desktop .frame-23 {
  flex: none;
  align-items: center;
  gap: 40px;
  margin-top: -1.5px;
  margin-bottom: -1.5px;
  display: inline-flex;
  position: relative;
}

.home-desktop .navigation {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

@media only screen and (width <= 1200px) {
  .home-desktop .frame-23 {
    gap: 20px;
  }

  .home-desktop .navigation {
    gap: 15px;
  }
}

.home-desktop .tab {
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.tab:hover {
  color: var(--brandbrand-3);
  border-bottom: 1px solid var(--brandbrand-3);
}

.home-desktop .frame-24 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .primary-4 {
  background-color: var(--brandmain);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 114px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .title-19 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .primary-5 {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 114px;
  padding: 12px;
  display: flex;
  position: relative;
}

@media only screen and (width <= 1200px) {
  .home-desktop .frame-24 {
    gap: 8px;
  }
}

.home-desktop .container-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 231px;
  left: 16px;
}

.home-desktop .title-20 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  width: 526px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .text-wrapper-10 {
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .button-5 {
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.home-desktop .primary-6 {
  background-color: var(--brandmain);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 43px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .seconday-3 {
  border: 1px solid;
  border-color: var(--brandbrand-3);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .section-5 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 60px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .frame-25 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.home-desktop .attribute-wrapper {
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: var(--greyprimary);
  border-radius: 8px;
  flex: 1;
  align-items: center;
  gap: 32px;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.home-desktop .airbnb-search-3 {
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: var(--greyprimary);
  border-radius: 8px;
  flex: none;
  align-items: center;
  gap: 32px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
}

@media only screen and (width <= 1440px) {
  .home-desktop .airbnb-search-3 {
    gap: 12px;
  }
}

.home-desktop .primary-7 {
  background-color: var(--brandmain);
  border-radius: 8px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 185px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .title-21 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.home-desktop .frame-26 {
  flex: 1;
  align-items: flex-start;
  gap: 24px;
  max-width: 1200px;
  display: inline-flex;
  position: relative;
}

.home-desktop .row-wrapper {
  background-color: #fff;
  border-radius: 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  width: 344px;
  display: flex;
  position: relative;
}

.home-desktop .image-3 {
  background-image: url("image.205e4202.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-27 {
  background-color: var(--brandmain);
  border-radius: 17px;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 10px;
  display: inline-flex;
  position: absolute;
  top: 13px;
  right: 11px;
}

.home-desktop .title-22 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home-desktop .subtitle-8 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.home-desktop .title-23 {
  -webkit-line-clamp: 1;
  color: var(--greygrey-5);
  letter-spacing: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 16px;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .vuesax-linear {
  width: 16px;
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -521px;
  position: relative;
}

.home-desktop .title-24 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home-desktop .vuesax-linear-clock {
  width: 16px;
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -413px;
  position: relative;
}

.home-desktop .vuesax-linear-2 {
  width: 16px;
  height: 16px;
  margin-bottom: -1767px;
  margin-left: -521px;
  position: relative;
}

.home-desktop .image-4 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.home-desktop .image-5 {
  background-image: url("image-2.7bc881d1.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .image-6 {
  background-image: url("image-3.76cc1769.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .image-container-3 {
  background-image: url("image-container-2.8a842d8f.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  display: flex;
  position: relative;
}

.home-desktop .image-7 {
  background-image: url("image-4.13fdff6d.svg");
  background-position: 50%;
  background-size: cover;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-28 {
  background-color: #fff;
  border-radius: 17px;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 10px;
  display: inline-flex;
  position: relative;
  top: 13px;
  left: 286px;
}

.home-desktop .frame-29 {
  width: 58.26px;
  height: 9.81px;
  margin-bottom: -1817.91px;
  margin-left: -1731.26px;
  position: relative;
}

.home-desktop .vuesax-linear-timer {
  width: 16px;
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -1777px;
  position: relative;
}

.home-desktop .section-6 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 77px 0 80px;
  display: flex;
  position: relative;
}

.home-desktop .union-9 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .frame-30 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 1200px;
  margin-top: -16px;
  display: flex;
  position: relative;
}

.home-desktop .frame-31 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.home-desktop .title-25 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  text-align: center;
  width: 100%;
  margin-top: -1px;
  position: relative;
}

.home-desktop .subtitle-9 {
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  text-align: center;
  width: 745px;
  position: relative;
}

.home-desktop .item-3 {
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  border-radius: 10px;
  align-items: flex-start;
  gap: 20px;
  max-width: 388px;
  height: 191px;
  padding: 24px 30px;
  display: flex;
  position: relative;
}

.home-desktop .frame-32 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 262px;
  display: flex;
  position: relative;
}

.home-desktop .item-4, .home-desktop .item-5, .home-desktop .item-6 {
  background-color: #fff;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  align-items: flex-start;
  gap: 20px;
  max-width: 388px;
  height: 191px;
  padding: 24px 30px;
  display: flex;
  position: relative;
}

.home-desktop .rectangle-3 {
  background: linear-gradient(#fff 0%, #fefefe00 100%);
  width: 100%;
  height: 77px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .section-7 {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  max-width: 1440px;
  height: 1283px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.home-desktop .frame-33 {
  width: 100%;
  max-width: 1440px;
  position: absolute;
  top: 522px;
  left: 0;
}

.home-desktop .frame-34 {
  width: 100%;
  max-width: 1440px;
}

.home-desktop .overlap-group-6 {
  width: 100%;
  position: relative;
}

.home-desktop .union-10 {
  width: 100%;
  position: absolute;
  left: -202px;
}

.home-desktop .mask-group {
  width: 100%;
  position: absolute;
}

.home-desktop .rectangle-4 {
  position: absolute;
  top: 237px;
}

.home-desktop .frame-35 {
  max-width: 756px;
  position: absolute;
  top: 34px;
  right: 0;
  overflow: hidden;
}

.home-desktop .container-4 {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  height: 940px;
  position: relative;
}

.home-desktop .title-26 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  text-align: center;
  left: 301px;
}

.home-desktop .container-procedure {
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 57px;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .home-desktop .container-procedure {
    gap: 24px;
  }
}

.home-desktop .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  max-width: 530px;
  display: inline-flex;
  top: 93px;
  left: 587px;
}

.home-desktop .article-2 {
  border-radius: 6px;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 16px;
  display: flex;
  position: relative;
}

.home-desktop .text-wrapper-11 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .title-27 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h3-font-family);
  font-size: var(--desktop-extrabold-h3-font-size);
  font-style: var(--desktop-extrabold-h3-font-style);
  font-weight: var(--desktop-extrabold-h3-font-weight);
  letter-spacing: var(--desktop-extrabold-h3-letter-spacing);
  line-height: var(--desktop-extrabold-h3-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .frame-37 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  max-width: 530px;
  display: inline-flex;
}

.home-desktop .frame-38 {
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  border-radius: 28px;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 6px 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .vector-2 {
  width: 51px;
  height: 34px;
  position: absolute;
  top: 836px;
  left: 301px;
}

.home-desktop .section-8 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.home-desktop .image-8 {
  background-color: var(--brandmain);
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 83px;
  padding: 43px 66px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .union-11 {
  width: 1044px;
  height: 314px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-desktop .title-28 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  max-width: 698px;
  margin-top: -1px;
  font-family: Encode Sans SC, Helvetica;
  font-size: 48px;
  font-weight: 900;
  line-height: 51px;
  position: relative;
}

.home-desktop .primary-8 {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 160px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .pagination {
  justify-content: center;
  align-items: center;
  gap: 10.91px;
  display: inline-flex;
  position: absolute;
  top: 285px;
  left: 490px;
}

.home-desktop .rectangle-5 {
  background-color: #fff;
  border-radius: 272.73px;
  width: 54.55px;
  height: 10.91px;
  position: relative;
}

.home-desktop .rectangle-6 {
  background-color: #0000004c;
  border-radius: 272.73px;
  width: 10.91px;
  height: 10.91px;
  position: relative;
}

.home-desktop .fits {
  max-width: 491px;
  position: absolute;
  top: -147px;
  right: 0;
}

.home-desktop .title-29 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  text-align: center;
  width: 100%;
  margin-bottom: 46px;
  position: relative;
}

.home-desktop .section-9 {
  background-color: #fff;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (width <= 1200px) {
  .home-desktop .section-9 {
    gap: 10px;
  }
}

.home-desktop .union-12 {
  object-fit: cover;
  max-width: 60%;
}

.home-desktop .union-13 {
  width: 599.41px;
  height: 461.88px;
  margin-top: -684.44px;
  margin-left: -697px;
  position: relative;
}

.home-desktop .list-wrapper {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.home-desktop .list-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.home-desktop .frame-39 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.home-desktop .featured-icon-2 {
  background-color: var(--brandmain);
  border: 8px solid #d6e84d8f;
  border-radius: 28px;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin-top: -4px;
  margin-left: -4px;
  display: flex;
  position: relative;
}

.home-desktop .title-30 {
  color: var(--greygrey-5);
  font-family: var(--mobile-extrabold-h2-font-family);
  font-size: var(--mobile-extrabold-h2-font-size);
  font-style: var(--mobile-extrabold-h2-font-style);
  font-weight: var(--mobile-extrabold-h2-font-weight);
  letter-spacing: var(--mobile-extrabold-h2-letter-spacing);
  line-height: var(--mobile-extrabold-h2-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .vector-3 {
  object-fit: cover;
  max-width: 1440px;
  height: 1px;
  position: absolute;
  top: 764px;
  left: 0;
}

.home-desktop .section-10 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  margin: 0 auto;
  padding: 56px 0;
  display: flex;
  position: relative;
}

.home-desktop .container-5 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .frame-40 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.home-desktop .card-3 {
  background-color: #fbfcef;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 361px;
  padding: 16px;
  display: flex;
  position: relative;
}

.home-desktop .avatar-8 {
  background-image: url("avatar-1.b2321684.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .title-31 {
  -webkit-line-clamp: 1;
  color: var(--greygrey-5);
  letter-spacing: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 20px;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .title-32 {
  -webkit-line-clamp: 3;
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 72px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-desktop .avatar-9 {
  background-image: url("avatar-2.598dd67d.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-10 {
  background-image: url("avatar-3.115457e4.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-11 {
  background-image: url("avatar-4.20ebacc3.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .frame-41 {
  width: 36.26px;
  height: 9.81px;
  position: relative;
}

.home-desktop .card-4 {
  background-color: #fbfcef;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 362px;
  padding: 16px;
  display: flex;
  position: relative;
}

.home-desktop .avatar-12 {
  background-image: url("avatar-5.947aecde.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-13 {
  background-image: url("avatar-6.fd3a313a.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .avatar-14 {
  background-image: url("avatar-7.895196fb.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  position: relative;
}

.home-desktop .vector-4 {
  object-fit: cover;
  max-width: 1440px;
  height: 1px;
  position: absolute;
  top: 512px;
  left: 0;
}

.home-desktop .section-11 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.home-desktop .subtitle-10 {
  color: var(--greygrey-5);
  letter-spacing: 0;
  text-align: center;
  width: 745px;
  height: 64px;
  font-family: Roboto, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.home-desktop .frame-42 {
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.home-desktop .US-divided-2 {
  width: 50%;
  position: relative;
}

.home-desktop .content-5 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 40%;
  display: flex;
  position: relative;
}

.home-desktop .content-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  margin-left: -3.17px;
  margin-right: -3.17px;
  display: flex;
  position: relative;
}

.home-desktop .form {
  flex-direction: column;
  flex: none;
  gap: 32px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.home-desktop .form-fields-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .div-8 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .content-7 {
  color: #565656;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  background: none;
  border: 0;
  flex: 1;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .actions {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

@media only screen and (width <= 1200px) {
  .home-desktop .frame-42 {
    gap: 10px;
  }

  .home-desktop .content-6 {
    align-items: flex-end;
  }

  .home-desktop .div-8 {
    gap: 10px;
  }
}

.home-desktop .button-base-5 {
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  box-shadow: var(--shadow-xs);
  cursor: pointer;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .text-7 {
  color: var(--greygrey-5);
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .vector-5 {
  object-fit: cover;
  width: 100%;
  max-width: 1440px;
  height: 1px;
  position: absolute;
  top: 792px;
  left: 0;
}

.home-desktop .section-12 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 16px;
  display: flex;
  position: relative;
}

.home-desktop .container-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .content-8 {
  background-color: var(--brandmain);
  border-radius: 16px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  padding: 64px;
  display: flex;
  position: relative;
}

.home-desktop .heading-and {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.home-desktop .heading-2 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h2-font-family);
  font-size: var(--desktop-extrabold-h2-font-size);
  font-style: var(--desktop-extrabold-h2-font-style);
  font-weight: var(--desktop-extrabold-h2-font-weight);
  letter-spacing: var(--desktop-extrabold-h2-letter-spacing);
  line-height: var(--desktop-extrabold-h2-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.home-desktop .supporting-text-3 {
  color: #646465;
  font-family: var(--text-xl-normal-font-family);
  font-size: var(--text-xl-normal-font-size);
  font-style: var(--text-xl-normal-font-style);
  font-weight: var(--text-xl-normal-font-weight);
  letter-spacing: var(--text-xl-normal-letter-spacing);
  line-height: var(--text-xl-normal-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .input-field-base-wrapper {
  flex-direction: column;
  align-items: flex-start;
  max-width: 360px;
  display: flex;
  position: relative;
}

.home-desktop .button-6 {
  all: unset;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.home-desktop .button-base-6 {
  all: unset;
  background-color: var(--greygrey-5);
  border: 1px solid;
  border-color: var(--greygrey-5);
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.home-desktop .vector-6 {
  object-fit: cover;
  max-width: 1440px;
  height: 1px;
  display: none;
  position: absolute;
  top: 332px;
  left: 0;
}

.home-desktop .footer-2 {
  background-color: var(--greygrey-5);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 64px;
  width: 100%;
  padding: 64px 0 48px;
  display: flex;
  position: relative;
}

.home-desktop .container-7 {
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  position: relative;
}

.home-desktop .logo-and-links {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 32px;
  display: flex;
  position: relative;
}

.home-desktop .supporting-text-4 {
  color: #828282;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  align-self: stretch;
  position: relative;
}

.home-desktop .text-8 {
  color: var(--brandmain);
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .text-9 {
  color: #828282;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-desktop .frame-43 {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.home-desktop .social-icon-2 {
  background-image: url("subtract.f641fbad.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: relative;
}

.home-desktop .seconday-4 {
  border: 1px solid #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  padding: 12px;
  display: flex;
  position: relative;
}

.home-desktop .container-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  max-width: 1200px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.home-desktop .content-9 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-desktop .footer-text-2 {
  color: #6f6f6f;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.home-desktop .footer-links-2 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.home-desktop .footer-link-3 {
  color: #6f6f6f;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button-base-5:hover, [class^="primary-"]:hover, [class^="seconday-"]:hover, [class^="primary"]:hover, [class^="seconday"]:hover {
  cursor: pointer;
  background-color: var(--brandbrand-3);
}

[class^="input-"]:hover {
  cursor: text;
}

[class^="checkbox-"]:hover {
  cursor: pointer;
}

textarea:focus-visible, input:focus-visible {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (width <= 350px) {
  .home-desktop .union-2 {
    width: 100%;
    max-width: 365px;
  }

  .home-desktop .untitled {
    width: 100%;
    max-width: 375px;
  }

  .home-desktop .union-3 {
    width: 100%;
    max-width: 369px;
  }

  .home-desktop .frame-8 {
    padding: 0;
  }

  .home-desktop .subtitle-2 {
    width: 100%;
    max-width: 314px;
    padding: 0 20px;
  }

  .home-desktop .subtitle-4 {
    width: 100%;
    max-width: 224px;
  }

  .home-desktop .subtitle-5 {
    width: 100%;
    max-width: 222px;
  }

  .home-desktop .content-2 {
    padding: 12px;
  }

  .home-desktop .social-icons {
    justify-content: space-between;
    gap: 0;
    width: 100%;
  }

  .home-desktop .frame-3 {
    padding: 0 10px;
  }

  .home-desktop .content-3 {
    width: 100%;
    font-size: 12px;
  }

  .home-desktop .frame-21 {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .home-desktop .text-wrapper-6, .home-desktop .text-wrapper-7 {
    position: static;
  }

  .home-desktop .overlap-group-3 {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    display: flex;
    position: static;
  }

  .home-desktop .union-5 {
    width: 100%;
    position: static;
  }

  .home-desktop .div-7 {
    gap: 8px;
  }
}

.slick-track {
  gap: 24px !important;
  display: flex !important;
}

@media only screen and (width <= 480px) {
  .slick-track {
    gap: 0 !important;
  }
}

.mobile-menu-container {
  z-index: 99999;
  background: #fff;
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100vh;
  margin-top: 8px;
  padding: 20px 32px;
  display: flex;
  position: fixed;
  top: 51px;
}

.mobile-menu-container .mobile-menu-item {
  font-family: var(--mobile-extrabold-h1-font-family);
  cursor: pointer;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
}

.privacy-page {
  width: 100%;
}

.privacy-page .main-banner {
  background: var(--Gray-50, #f9fafb);
  width: 100%;
}

.privacy-page .main-banner .container-main {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
}

.privacy-page .title-banner {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.privacy-page .sub-title {
  color: var(--Brand-Brand--2, #9aac16);
  text-align: center;
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.privacy-page .main-title {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.privacy-page .desc-title {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: var(--desktop-extrabold-h1-font-family);
  max-width: 768px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.privacy-page .main-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 96px 0;
  display: flex;
}

.privacy-page p.desc-content {
  color: var(--Gray-500, #667085);
  font-family: var(--desktop-extrabold-h1-font-family);
  max-width: 720px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.privacy-page .privacy-container {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 720px;
  display: flex;
}

.privacy-page h1.title {
  color: var(--Gray-900, #101828);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.privacy-page h1.title.smaill-size {
  color: var(--Gray-900, #101828);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.privacy-page .desc {
  color: var(--Gray-500, #667085);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (width <= 899px) {
  .privacy-page {
    width: 100%;
  }

  .privacy-page .main-banner .container-main {
    padding-top: 0;
    padding-bottom: 0;
  }

  .privacy-page .main-banner {
    padding: 52px 16px;
  }

  .privacy-page .sub-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .privacy-page .main-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .privacy-page .desc-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .privacy-page .main-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 64px 16px;
    display: flex;
  }

  .privacy-page p.desc-content, .privacy-page .privacy-container {
    width: 100%;
  }

  .privacy-page .section-8 {
    display: none;
  }
}

.term-page {
  width: 100%;
}

.term-page .main-banner {
  background: var(--Gray-50, #f9fafb);
  width: 100%;
}

.term-page .main-banner .container-main {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
}

.term-page .title-banner {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.term-page .sub-title {
  color: var(--Primary-600, #338dcd);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.term-page .main-title {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.term-page .desc-title {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: var(--desktop-extrabold-h1-font-family);
  max-width: 768px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.term-page .list-button {
  border: 1px solid var(--Gray-100, #f2f4f7);
  background: var(--Gray-100, #f2f4f7);
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 6px;
  display: flex;
}

.term-page .default-btn {
  background: var(--Base-White, #fff);
  cursor: pointer;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  display: flex;
  box-shadow: 0 1px 2px #1018280f, 0 1px 3px #1018281a;
}

.term-page .secondary-btn {
  cursor: pointer;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  display: flex;
}

.term-page .main-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 96px 0;
  display: flex;
}

.term-page p.desc-content {
  color: var(--Gray-500, #667085);
  font-family: var(--desktop-extrabold-h1-font-family);
  max-width: 720px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.term-page .privacy-container {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 720px;
  display: flex;
}

.term-page h1.title {
  color: var(--Gray-900, #101828);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.term-page h1.title.smaill-size {
  color: var(--Gray-900, #101828);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.term-page .desc {
  color: var(--Gray-500, #667085);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.term-page .mobile-main-banner {
  background: var(--Primary-800, #005490);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 64px 16px;
  display: flex;
}

.term-page .mobile-title-banner {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.term-page .mobile-sub-title {
  color: var(--Primary-200, #bddaef);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.term-page .mobile-main-title {
  color: var(--Base-White, #fff);
  text-align: center;
  letter-spacing: -.72px;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}

.term-page .mobile-desc-title {
  color: var(--Primary-200, #bddaef);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.term-page .mobile-list-button {
  border: 1px solid var(--Primary-100, #cee3f3);
  border-radius: 8px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 6px;
  display: flex;
}

.term-page .mobile-default-btn {
  background: var(--Base-White, #fff);
  cursor: pointer;
  border-radius: 6px;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  display: flex;
  box-shadow: 0 1px 2px #1018280f, 0 1px 3px #1018281a;
}

.term-page .mobile-secondary-btn {
  cursor: pointer;
  border-radius: 6px;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  display: flex;
}

@media only screen and (width <= 899px) {
  .term-page {
    width: 100%;
  }

  .term-page .main-banner {
    padding: 52px 32px;
  }

  .term-page .sub-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .main-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .desc-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .term-page .main-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 64px 16px;
    display: flex;
  }

  .term-page p.desc-content, .term-page .privacy-container {
    width: 100%;
  }

  .term-page .section-12 {
    padding: 60px 16px;
  }

  .term-page content-6, .term-page .container-6 {
    padding: 0;
  }

  .term-page .content-3 {
    text-align: center;
  }

  .term-page .content-8 {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px;
  }

  .term-page .heading-and {
    text-align: center;
    width: 100%;
  }

  .term-page .heading-2 {
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .supporting-text-3 {
    color: var(--Grey-Grey--2, #636366);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .term-page .div-7 {
    flex-direction: column;
    width: 100%;
  }

  .term-page .input-field-base-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .term-page .hint-text {
    text-align: center;
  }

  .term-page .button-6, .term-page .button-base-6 {
    width: 100%;
  }
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-\[100px\] {
  margin-top: 100px;
  margin-bottom: 100px;
}

.\!items-start {
  align-items: flex-start !important;
}
/*# sourceMappingURL=index.adc9bfba.css.map */
