.container-main {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.home-desktop {
  background-color: #ffffff;
  position: relative;
  cursor: default;
}

.home-desktop .overlap {
  width: 100%;
  /* width: 375px; */
  /* height: 9205px; */
}

.home-desktop .union {
  height: 991px;
  left: 0;
  position: absolute;
  top: 2051px;
  width: 375px;
}

.home-desktop .rectangle {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(254.15, 254.15, 254.15, 0) 100%
  );
  height: 215px;
  left: 0;
  position: absolute;
  top: 1754px;
  transform: rotate(180deg);
  width: 375px;
}

.home-desktop .div {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(254.15, 254.15, 254.15, 0) 100%
  );
  height: 215px;
  left: 0;
  position: absolute;
  top: 6740px;
  transform: rotate(180deg);
  width: 375px;
}

.home-desktop .frame {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home-desktop .content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 12px 8px 12px 16px;
  position: relative;
  width: 100%;
  height: 48px;
}

.home-desktop .frame-2 {
  position: relative;
}

.home-desktop .overlap-group {
  height: 19px;
  left: 35px;
  position: absolute;
  top: 6px;
  width: 59px;
}

.home-desktop .text-wrapper {
  color: #000000;
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 4.5px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  bottom: -4.5px;
}

.home-desktop .text-wrapper-2 {
  color: #000000;
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 19.7px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-desktop .img-2 {
  height: 24px;
  position: relative;
  width: 24px;
  cursor: pointer;
  z-index: 1000;
}

.home-desktop .section {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.home-desktop .overlap-group-wrapper {
  position: relative;
  width: 100%;
}

.home-desktop .overlap-group-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 517px;
  position: relative;
  width: 100%;
}

.home-desktop .union-2 {
  height: 283px;
  position: absolute;
  top: 128px;
  width: 365px;
}

.home-desktop .untitled {
  height: 517px;
  position: absolute;
  top: 0;
  width: 375px;
}

.home-desktop .union-3 {
  height: 284px;
  position: absolute;
  top: 118px;
  width: 369px;
}

.home-desktop .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.51px;
  position: relative;
  width: 100%;
}

.home-desktop .title {
  text-align: center;
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -0.56px;
  position: relative;
}

.home-desktop .p {
  text-align: center;
  color: #000000;
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  position: relative;
}

.home-desktop .button {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11.86px;
  margin-right: -1px;
  position: relative;
}

@media only screen and (max-width: 350px) {
  .home-desktop .button {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 auto;
    gap: 11.86px;
    margin-right: -1px;
    position: relative;
  }
}

.home-desktop .primary {
  align-items: center;
  background-color: #d6e84d;
  border-radius: 7.9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11.86px;
  position: relative;
  width: 158.07px;
}

.home-desktop .title-2 {
  color: #000000;
  font-family: 'Roboto', Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23.7px;
  margin-top: -0.99px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .seconday {
  align-items: center;
  border: 0.99px solid;
  border-color: var(--brandbrand-3);
  border-radius: 7.9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11.86px;
  position: relative;
  width: 158.07px;
}

.home-desktop .section-2 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  justify-content: center;

  position: relative;
  width: 100%;
  padding: 32px 0 60px;
}

.home-desktop .frame-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-left: -0.5px;
  margin-right: -0.5px;
  position: relative;
  width: 100%;
  max-width: 328px;
}

.home-desktop .airbnb-search {
  align-items: center;
  align-self: stretch;
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.home-desktop .attribute {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 193px;
}

@media only screen and (max-width: 1440px) {
  .home-desktop .attribute {
    max-width: 193px;
    width: 100%;
  }
}

.home-desktop .attribute-2 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  margin-top: -1px;
  position: relative;
  width: 162px;
}

.home-desktop .value {
  color: #7d7d7d;
  font-family: var(--desktop-medium-h7-font-family);
  font-size: var(--desktop-medium-h7-font-size);
  font-style: var(--desktop-medium-h7-font-style);
  font-weight: var(--desktop-medium-h7-font-weight);
  letter-spacing: var(--desktop-medium-h7-letter-spacing);
  line-height: var(--desktop-medium-h7-line-height);
  position: relative;
  width: fit-content;
}

.home-desktop .airbnb-search-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: #c2c2c2;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.home-desktop .primary-2 {
  align-items: center;
  align-self: stretch;
  background-color: #d6e84d;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 100%;
}

.home-desktop .title-3 {
  color: #000000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  position: relative;
  width: fit-content;
}

.home-desktop .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  margin-left: -8.5px;
  margin-right: -8.5px;
  position: relative;
  width: 100%;
  padding: 0px 20px;
}

.home-desktop .list {
  align-items: center;
  background-color: #ffffff;
  border-radius: 21px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19px;
  justify-content: center;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 480px) {
    padding: 0 16px;
  }
}

.home-desktop .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.home-desktop .card {
  /* align-items: center; */
  border: 1px solid #0000001a;
  border-radius: 6px;
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: column; */
  /* flex-grow: 1; */
  overflow: hidden;
  position: relative;
  width: 100%;
}

.home-desktop .image-container {
  align-items: flex-start;
  display: flex;
  height: 150px;
  overflow: hidden;
}

.home-desktop .image {
  position: relative;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.home-desktop .title-wrapper {
  align-items: flex-start;
  background-color: #d4e64d;
  border-radius: 17px;
  display: inline-flex;
  gap: 10px;
  right: 11px;
  padding: 4px 10px;
  position: absolute;
  top: 13px;
}

.home-desktop .title-4 {
  color: #000000;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .text-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  padding: 8px 12px;
  position: relative;
  width: 100%;
}

.home-desktop .subtitle {
  align-self: stretch;
  color: #000000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .user {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 100%;
}

.home-desktop .avatar {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.home-desktop .avatar-2 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 24px;
  height: 24px;
  position: relative;
  width: 24px;
}

.home-desktop .div-wrapper {
  width: 40%;
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.home-desktop .title-5 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #000000;
  display: -webkit-box;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-desktop .frame-5 {
  height: 9.81px;
  position: relative;
  width: 58.26px;
}

.home-desktop .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  opacity: 0.5;
  position: relative;
  width: 100%;
}

.home-desktop .img-3 {
  height: 16px;
  position: relative;
  width: 16px;
}

.home-desktop .title-6 {
  color: #000000;
  flex: 1;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .image-wrapper {
  align-items: flex-start;
  /* align-self: stretch; */
  /* background-position: 50% 50%; */
  /* background-size: cover; */
  display: flex;
  height: 150px;
  /* position: relative; */
  /* width: 100%; */
}

.home-desktop .frame-wrapper {
  align-self: stretch;
  background-image: url(../../../static/img/image-6.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .image-container-2 {
  align-items: flex-start;
  align-self: stretch;
  background-image: url(../../../static/img/image-container-1.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 150px;
  position: relative;
  width: 100%;
}

.home-desktop .image-2 {
  align-self: stretch;
  background-image: url(../../../static/img/image-7.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .frame-7 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 35px;
  padding: 32px 0px;
  position: relative;
  width: 100%;
}

.home-desktop .frame-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.home-desktop .title-7 {
  align-self: stretch;
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.home-desktop .subtitle-2 {
  color: #000000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
  text-align: center;
  width: 314px;
}

.home-desktop .frame-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.home-desktop .item {
  align-items: flex-start;
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  padding: 24px 30px;
  position: relative;
  max-width: 450px;
}

.home-desktop .vuesax-linear-wrapper {
  align-items: flex-start;
  background-color: var(--brandbrand-3);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.home-desktop .frame-10 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.home-desktop .title-8 {
  align-self: stretch;
  color: #000000;
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .item-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bbbaba;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  padding: 24px 30px;
  position: relative;
  max-width: 450px;
}

.home-desktop .img-wrapper {
  align-items: flex-start;
  background-color: var(--grey-grey-3, #e5e5ea);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.home-desktop .subtitle-3 {
  align-self: stretch;
  color: var(--greygrey-2);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  position: relative;
}

.home-desktop .frame-11 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
  padding: 32px 20px;
  align-items: center;
  justify-content: center;
}

.home-desktop .div-2 {
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.home-desktop .article {
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  position: relative;
  max-width: 450px;
}

.home-desktop .text-wrapper-3 {
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .vector {
  height: 83px;
  position: relative;
  width: 3px;
}

.home-desktop .frame-12 {
  align-items: center;
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 6px 16px;
  position: relative;
}

.home-desktop .title-9 {
  color: #000000;
  font-family: var(--mobile-extrabold-h6-font-family);
  font-size: var(--mobile-extrabold-h6-font-size);
  font-style: var(--mobile-extrabold-h6-font-style);
  font-weight: var(--mobile-extrabold-h6-font-weight);
  letter-spacing: var(--mobile-extrabold-h6-letter-spacing);
  line-height: var(--mobile-extrabold-h6-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .title-10 {
  align-self: stretch;
  color: #000000;
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  position: relative;
}

.home-desktop .subtitle-4 {
  color: #000000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
  width: 224px;
}

.home-desktop .subtitle-5 {
  color: #000000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
  width: 222px;
}

.home-desktop .frame-13 {
  align-items: center;
  background-color: var(--Grey-Grey--5, #1c1c1e);
  border: 1px solid;
  border-color: var(--Grey-Grey--5, #1c1c1e);
  border-radius: 28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 6px 16px;
  position: relative;
}

.home-desktop .title-11 {
  color: var(--grey-grey-5, #fff);
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .title-11-black {
  color: #000000;
  font-family: var(--desktop-extrabold-h7-font-family);
  font-size: var(--desktop-extrabold-h7-font-size);
  font-style: var(--desktop-extrabold-h7-font-style);
  font-weight: var(--desktop-extrabold-h7-font-weight);
  letter-spacing: var(--desktop-extrabold-h7-letter-spacing);
  line-height: var(--desktop-extrabold-h7-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .frame-14 {
  align-items: center;
  background-color: var(--grey-grey-3, #e5e5ea);
  border-radius: 28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 6px 16px;
  position: relative;
}

.home-desktop .rectangle-2 {
  position: relative;
  width: 100%;
}

.home-desktop .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  margin-left: -9.12px;
  margin-right: -9.12px;
  padding: 32px 0px;
  position: relative;
  width: 100%;
  padding: 32px 20px;
}

.home-desktop .title-12 {
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.home-desktop .union-4 {
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-desktop .frame-16 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.home-desktop .frame-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 24px 29px;
  position: relative;
  width: 100%;
}

.home-desktop .featured-icon {
  background-color: #d6e84d;
  border: 8px solid;
  border-color: #d6e84d8f;
  border-radius: 28px;
  height: 56px;
  margin-left: -4px;
  margin-top: -4px;
  position: relative;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-desktop .img-4 {
  height: 24px;
  left: 12px;
}

.home-desktop .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.home-desktop .title-13 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: var(--mobile-extrabold-h3-font-family);
  font-size: var(--mobile-extrabold-h3-font-size);
  font-style: var(--mobile-extrabold-h3-font-style);
  font-weight: var(--mobile-extrabold-h3-font-weight);
  letter-spacing: var(--mobile-extrabold-h3-letter-spacing);
  line-height: var(--mobile-extrabold-h3-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .subtitle-6 {
  align-self: stretch;
  color: var(--greygrey-2);
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
}

.home-desktop .frame-18 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  padding: 32px 20px;
  position: relative;
  width: 100%;
}

.home-desktop .title-14 {
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.home-desktop .frame-19 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.home-desktop .card-2 {
  align-items: center;
  align-self: stretch;
  background-color: #fbfcef;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 152px;
  justify-content: center;
  overflow: hidden;
  padding: 16px;
  position: relative;
  width: 100%;
}

.home-desktop .avatar-3 {
  background-image: url(../../../static/img/avatar-8.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .title-15 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #000000;
  display: -webkit-box;
  font-family: 'Roboto', Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-desktop .title-16 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  color: #000000;
  display: -webkit-box;
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  height: 72px;
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.home-desktop .avatar-4 {
  background-image: url(../../../static/img/avatar-9.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-5 {
  background-image: url(../../../static/img/avatar-10.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-6 {
  background-image: url(../../../static/img/avatar-11.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-7 {
  background-image: url(../../../static/img/avatar-12.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .section-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 52px;
  height: 1228px;
  justify-content: center;
  margin-left: -0.5px;
  margin-right: -0.5px;
  padding: 32px 20px;
  position: relative;
  width: 100%;
}

.home-desktop .subtitle-7 {
  align-self: stretch;
  color: #000000;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
  text-align: center;
}

.home-desktop .frame-20 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .US-divided {
  position: relative;
  width: 100%;
}

.home-desktop .content-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .form-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.home-desktop .div-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .form-fields {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.home-desktop .input-field {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.home-desktop .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.home-desktop .label {
  background: transparent;
  border: none;
  color: #58595c;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .input {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f8;
  border: 1px solid;
  border-color: #cecece;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

.home-desktop .text {
  background: none;
  border: none;
  color: #565656;
  flex: 1;
  flex-grow: 1;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .textarea-input-field {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 154px;
  position: relative;
  width: 100%;
}

.home-desktop .div-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  position: relative;
  width: 100%;
}

.home-desktop .label-2 {
  color: #58595c;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .input-2 {
  align-items: center;
  align-self: stretch;
  background-color: #f8f8f8;
  border: 1px solid;
  border-color: #cecece;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  padding: 10px 14px;
  position: relative;
  width: 100%;
}

.home-desktop .checkbox {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.home-desktop .checkbox-2 {
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid;
  border-color: #474747;
  border-radius: 5px;
  box-shadow: var(--shadow-xs);
  display: flex;
  gap: 8px;
  height: 20px;
  position: relative;
  width: 20px;
}

.home-desktop .text-2 {
  color: #808080;
  flex: 1;
  font-family: 'Inter', Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .span {
  color: #808080;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
}

.home-desktop .text-wrapper-4 {
  text-decoration: underline;
  cursor: pointer;
}

.home-desktop .button-base-wrapper {
  all: unset;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.home-desktop .button-base {
  align-items: center;
  background-color: #d6e84d;
  border: 1px solid;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  cursor: pointer;
}

.home-desktop .text-3 {
  color: #000000;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .section-4 {
  align-items: center;
  display: flex;
  gap: 60px;
  height: 410px;
  justify-content: center;
  margin-left: -0.5px;
  margin-right: -0.5px;
  padding: 32px 20px;
  position: relative;
  width: 100%;
}

.home-desktop .content-2 {
  align-items: flex-start;
  background-color: #d6e84d;
  border-radius: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 24px;
  position: relative;
}

.home-desktop .heading {
  align-self: stretch;
  color: #000000;
  font-family: var(--mobile-extrabold-h1-font-family);
  font-size: var(--mobile-extrabold-h1-font-size);
  font-style: var(--mobile-extrabold-h1-font-style);
  font-weight: var(--mobile-extrabold-h1-font-weight);
  letter-spacing: var(--mobile-extrabold-h1-letter-spacing);
  line-height: var(--mobile-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .supporting-text {
  align-self: stretch;
  color: var(--greygrey-2);
  font-family: var(--mobile-medium-h4-font-family);
  font-size: var(--mobile-medium-h4-font-size);
  font-style: var(--mobile-medium-h4-font-style);
  font-weight: var(--mobile-medium-h4-font-weight);
  letter-spacing: var(--mobile-medium-h4-letter-spacing);
  line-height: var(--mobile-medium-h4-line-height);
  position: relative;
}

.home-desktop .input-3 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 12px 14px 12px 16px;
  position: relative;
  width: 100%;
}

.home-desktop .content-3 {
  background: none;
  border: none;
  color: #6b6b6b;
  flex: 1;
  flex-grow: 1;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .hint-text {
  align-self: stretch;
  color: #6b6b6b;
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.home-desktop .text-wrapper-5 {
  color: #6b6b6b;
  font-family: var(--text-sm-normal-font-family);
  font-size: var(--text-sm-normal-font-size);
  font-style: var(--text-sm-normal-font-style);
  font-weight: var(--text-sm-normal-font-weight);
  letter-spacing: var(--text-sm-normal-letter-spacing);
  line-height: var(--text-sm-normal-line-height);
}

.home-desktop .button-base-2 {
  all: unset;
  align-items: center;
  background-color: #000000;
  border: 1px solid;
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  cursor: pointer;
}

.home-desktop .text-4 {
  color: #ffffff;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .footer {
  align-items: center;
  background-color: #000000;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  padding: 32px 24px;
  position: relative;
  width: 100%;
}

.home-desktop .logo-and-supporting {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .frame-21 {
  height: 46.14px;
  position: relative;
  width: 179.73px;
}

.home-desktop .overlap-group-3 {
  height: 36px;
  left: 66px;
  position: absolute;
  top: 10px;
  width: 110px;
}

.home-desktop .text-wrapper-6 {
  color: #ffffff;
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 8.4px;
  font-weight: 400;
  left: 101px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.home-desktop .text-wrapper-7 {
  color: #ffffff;
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 36.9px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-desktop .union-5 {
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.home-desktop .supporting-text-2 {
  align-self: stretch;
  color: #828282;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  position: relative;
}

.home-desktop .footer-links {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 32px 16px;
  position: relative;
  width: 100%;
}

.home-desktop .footer-link {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.home-desktop .button-2 {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home-desktop .button-base-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.home-desktop .text-5 {
  color: #d6e84d;
  font-family: var(--mobile-extrabold-h5-font-family);
  font-size: var(--mobile-extrabold-h5-font-size);
  font-style: var(--mobile-extrabold-h5-font-style);
  font-weight: var(--mobile-extrabold-h5-font-weight);
  letter-spacing: var(--mobile-extrabold-h5-letter-spacing);
  line-height: var(--mobile-extrabold-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.home-desktop .text-6 {
  color: #828282;
  font-family: var(--mobile-extrabold-h5-font-family);
  font-size: var(--mobile-extrabold-h5-font-size);
  font-style: var(--mobile-extrabold-h5-font-style);
  font-weight: var(--mobile-extrabold-h5-font-weight);
  letter-spacing: var(--mobile-extrabold-h5-letter-spacing);
  line-height: var(--mobile-extrabold-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.home-desktop .button-wrapper {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.home-desktop .button-base-4 {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.home-desktop .button-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home-desktop .social-icons {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.home-desktop .social-icon {
  background-image: url(../../../static/img/subtract-1.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.home-desktop .button-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.home-desktop .primary-3 {
  align-items: center;
  align-self: stretch;
  background-color: #d6e84d;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 100%;
}

.home-desktop .title-17 {
  color: #000000;
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .seconday-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 100%;
}

.home-desktop .title-18 {
  color: #ffffff;
  font-family: 'Roboto', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .container-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.home-desktop .divider {
  align-self: stretch;
  background-color: #282828;
  height: 1px;
  position: relative;
  width: 100%;
}

.home-desktop .content-4 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .footer-text {
  align-self: stretch;
  color: #6f6f6f;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .div-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.home-desktop .footer-link-2 {
  color: #6f6f6f;
  font-family: var(--mobile-medium-h5-font-family);
  font-size: var(--mobile-medium-h5-font-size);
  font-style: var(--mobile-medium-h5-font-style);
  font-weight: var(--mobile-medium-h5-font-weight);
  letter-spacing: var(--mobile-medium-h5-letter-spacing);
  line-height: var(--mobile-medium-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.home-desktop .top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 43px;
}

.home-desktop .overlap-wrapper {
  height: 689px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.home-desktop .overlap-2 {
  position: relative;
}

.home-desktop .overlap-3 {
  width: 100%;
}

.home-desktop .graphic-element {
  height: 756px;
  right: 0;
  position: absolute;
  top: 52px;
  width: 633px;
}

.home-desktop .overlap-group-4 {
  height: 722px;
  position: relative;
  width: 605px;
}

.home-desktop .union-6 {
  height: 396px;
  left: 69px;
  position: absolute;
  top: 179px;
  width: 513px;
}

.home-desktop .untitled-2 {
  height: 722px;
  left: 0;
  position: absolute;
  top: 0;
  width: 605px;
}

.home-desktop .union-7 {
  height: 397px;
  left: 59px;
  position: absolute;
  top: 164px;
  width: 516px;
}

.home-desktop .top-bar {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 20px 16px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.home-desktop .frame-22 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home-desktop .overlap-group-5 {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: center;
}

.home-desktop .text-wrapper-8 {
  color: var(--greygrey-5);
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 6.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.home-desktop .text-wrapper-9 {
  color: var(--greygrey-5);
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 28.7px;
  font-weight: 700;

  letter-spacing: 0;
  line-height: normal;

  white-space: nowrap;
}

.home-desktop .union-8 {
  width: 100%;
}

.home-desktop .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  margin-bottom: -1.5px;
  margin-top: -1.5px;
  position: relative;
}

.home-desktop .navigation {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .home-desktop .frame-23 {
    gap: 20px;
  }

  .home-desktop .navigation {
    gap: 15px;
  }
}
.home-desktop .tab {
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.tab:hover {
  color: var(--brandbrand-3);
  border-bottom: 1px solid var(--brandbrand-3);
}

.home-desktop .frame-24 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.home-desktop .primary-4 {
  align-items: center;
  background-color: var(--brandmain);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 114px;
}

.home-desktop .title-19 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h6-font-family);
  font-size: var(--desktop-extrabold-h6-font-size);
  font-style: var(--desktop-extrabold-h6-font-style);
  font-weight: var(--desktop-extrabold-h6-font-weight);
  letter-spacing: var(--desktop-extrabold-h6-letter-spacing);
  line-height: var(--desktop-extrabold-h6-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .primary-5 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 114px;
}
@media only screen and (max-width: 1200px) {
  .home-desktop .frame-24 {
    gap: 8px;
  }
}

.home-desktop .container-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 16px;
  position: absolute;
  top: 231px;
}

.home-desktop .title-20 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  width: 526px;
}

.home-desktop .text-wrapper-10 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  position: relative;
}

.home-desktop .button-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.home-desktop .primary-6 {
  align-items: center;
  background-color: var(--brandmain);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 160px;
  height: 43px;
}

.home-desktop .seconday-3 {
  align-items: center;
  border: 1px solid;
  border-color: var(--brandbrand-3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 160px;
}

.home-desktop .section-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  overflow: hidden;
  padding: 60px 0px;
  position: relative;
  width: 100%;
}

.home-desktop .frame-25 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
}

.home-desktop .attribute-wrapper {
  align-items: center;
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: var(--greyprimary);
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 32px;
  padding: 8px 16px;
  position: relative;
}

.home-desktop .airbnb-search-3 {
  align-items: center;
  background-color: var(--grey-grey-5, #fff);
  border: 1px solid;
  border-color: var(--greyprimary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  padding: 8px 16px;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .home-desktop .airbnb-search-3 {
    gap: 12px;
  }
}

.home-desktop .primary-7 {
  align-items: center;
  align-self: stretch;
  background-color: var(--brandmain);
  border-radius: 8px;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 185px;
}

.home-desktop .title-21 {
  color: var(--greygrey-5);
  font-family: 'Roboto', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  max-width: 1200px;
  flex: 1 1 0%;
}

.home-desktop .row-wrapper {
  align-items: center;
  background-color: #ffffff;
  border-radius: 21px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  justify-content: center;
  position: relative;
  width: 344px;
}

.home-desktop .image-3 {
  align-self: stretch;
  background-image: url(../../../static/img/image.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .frame-27 {
  align-items: flex-start;
  background-color: var(--brandmain);
  border-radius: 17px;
  display: inline-flex;
  gap: 10px;
  right: 11px;
  padding: 4px 10px;
  position: absolute;
  top: 13px;
}

.home-desktop .title-22 {
  color: var(--greygrey-5);
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .subtitle-8 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: 'Roboto', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .title-23 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--greygrey-5);
  display: -webkit-box;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-desktop .vuesax-linear {
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -521px;
  position: relative;
  width: 16px;
}

.home-desktop .title-24 {
  color: var(--greygrey-5);
  flex: 1;
  font-family: 'Roboto', Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
}

.home-desktop .vuesax-linear-clock {
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -413px;
  position: relative;
  width: 16px;
}

.home-desktop .vuesax-linear-2 {
  height: 16px;
  margin-bottom: -1767px;
  margin-left: -521px;
  position: relative;
  width: 16px;
}

.home-desktop .image-4 {
  align-self: stretch;
  position: relative;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.home-desktop .image-5 {
  align-self: stretch;
  background-image: url(../../../static/img/image-2.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .image-6 {
  align-self: stretch;
  background-image: url(../../../static/img/image-3.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .image-container-3 {
  align-items: flex-start;
  align-self: stretch;
  background-image: url(../../../static/img/image-container-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 150px;
  position: relative;
  width: 100%;
}

.home-desktop .image-7 {
  align-self: stretch;
  background-image: url(../../../static/img/image-4.svg);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.home-desktop .frame-28 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 17px;
  display: inline-flex;
  gap: 10px;
  left: 286px;
  padding: 4px 10px;
  position: relative;
  top: 13px;
}

.home-desktop .frame-29 {
  height: 9.81px;
  margin-bottom: -1817.91px;
  margin-left: -1731.26px;
  position: relative;
  width: 58.26px;
}

.home-desktop .vuesax-linear-timer {
  height: 16px;
  margin-bottom: -1792px;
  margin-left: -1777px;
  position: relative;
  width: 16px;
}

.home-desktop .section-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 77px 0px 80px;
  position: relative;
  width: 100%;
}

.home-desktop .union-9 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.home-desktop .frame-30 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-top: -16px;
  position: relative;
  max-width: 1200px;
  width: 100%;
}

.home-desktop .frame-31 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-desktop .title-25 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 100%;
}

.home-desktop .subtitle-9 {
  color: var(--greygrey-5);
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  position: relative;
  text-align: center;
  width: 745px;
}

.home-desktop .item-3 {
  align-items: flex-start;
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  height: 191px;
  padding: 24px 30px;
  position: relative;
  max-width: 388px;
}

.home-desktop .frame-32 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 262px;
}

.home-desktop .item-4 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bbbaba;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  height: 191px;
  padding: 24px 30px;
  position: relative;
  max-width: 388px;
}

.home-desktop .item-5 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bbbaba;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  padding: 24px 30px;
  position: relative;
  max-width: 388px;
  height: 191px;
}

.home-desktop .item-6 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #bbbaba;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  height: 191px;
  padding: 24px 30px;
  position: relative;
  max-width: 388px;
}

.home-desktop .rectangle-3 {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(254.15, 254.15, 254.15, 0) 100%
  );
  height: 77px;
  left: 0px;
  position: absolute;
  top: 0;
  width: 100%;
}

.home-desktop .section-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 60px;
  width: 100%;
  max-width: 1440px;
  height: 1283px;
  justify-content: center;
  /* padding: 96px 170px; */
  margin: 0 auto;
  position: relative;
}

.home-desktop .frame-33 {
  position: absolute;
  top: 522px;
  width: 100%;
  left: 0;
  max-width: 1440px;
}

.home-desktop .frame-34 {
  width: 100%;
  max-width: 1440px;
}

.home-desktop .overlap-group-6 {
  width: 100%;
  position: relative;
}

.home-desktop .union-10 {
  position: absolute;
  width: 100%;
  left: -202px;
}

.home-desktop .mask-group {
  position: absolute;

  width: 100%;
}

.home-desktop .rectangle-4 {
  position: absolute;
  top: 237px;
}

.home-desktop .frame-35 {
  overflow: hidden;
  right: 0;
  position: absolute;
  top: 34px;
  max-width: 756px;
}

.home-desktop .container-4 {
  flex: 1;
  flex-grow: 1;
  height: 940px;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.home-desktop .title-26 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  left: 301px;
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);

  text-align: center;
}

.home-desktop .container-procedure {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  gap: 57px;
}

@media only screen and (max-width: 1200px) {
  .home-desktop .container-procedure {
    gap: 24px;
  }
}

.home-desktop .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 587px;
  top: 93px;
  max-width: 530px;
}

.home-desktop .article-2 {
  align-items: flex-start;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 100%;
}

.home-desktop .text-wrapper-11 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-desktop .title-27 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h3-font-family);
  font-size: var(--desktop-extrabold-h3-font-size);
  font-style: var(--desktop-extrabold-h3-font-style);
  font-weight: var(--desktop-extrabold-h3-font-weight);
  letter-spacing: var(--desktop-extrabold-h3-letter-spacing);
  line-height: var(--desktop-extrabold-h3-line-height);
  position: relative;
}

.home-desktop .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  max-width: 530px;
}

.home-desktop .frame-38 {
  align-items: center;
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  border-radius: 28px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  padding: 6px 16px;
  position: relative;
}

.home-desktop .vector-2 {
  height: 34px;
  left: 301px;
  position: absolute;
  top: 836px;
  width: 51px;
}

.home-desktop .section-8 {
  flex: 0 0 auto;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 60px 16px;
  margin: 0 auto;
}

.home-desktop .image-8 {
  background-color: var(--brandmain);
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 83px;
  padding: 43px 66px;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-desktop .union-11 {
  height: 314px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1044px;
}

.home-desktop .title-28 {
  color: var(--greygrey-5);
  font-family: 'Encode Sans SC', Helvetica;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 51px;
  margin-top: -1px;
  position: relative;
  max-width: 698px;
}

.home-desktop .primary-8 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 160px;
}

.home-desktop .pagination {
  align-items: center;
  display: inline-flex;
  gap: 10.91px;
  justify-content: center;
  left: 490px;
  position: absolute;
  top: 285px;
}

.home-desktop .rectangle-5 {
  background-color: #ffffff;
  border-radius: 272.73px;
  height: 10.91px;
  position: relative;
  width: 54.55px;
}

.home-desktop .rectangle-6 {
  background-color: #0000004c;
  border-radius: 272.73px;
  height: 10.91px;
  position: relative;
  width: 10.91px;
}

.home-desktop .fits {
  max-width: 491px;
  position: absolute;
  top: -147px;
  right: 0px;
}

.home-desktop .title-29 {
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: var(--desktop-extrabold-h1-font-size);
  font-style: var(--desktop-extrabold-h1-font-style);
  font-weight: var(--desktop-extrabold-h1-font-weight);
  letter-spacing: var(--desktop-extrabold-h1-letter-spacing);
  line-height: var(--desktop-extrabold-h1-line-height);
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 46px;
}

.home-desktop .section-9 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

@media only screen and (max-width: 1200px) {
  .home-desktop .section-9 {
    gap: 10px;
  }
}

.home-desktop .union-12 {
  object-fit: cover;
  max-width: 60%;
}

.home-desktop .union-13 {
  height: 461.88px;
  margin-left: -697px;
  margin-top: -684.44px;
  position: relative;
  width: 599.41px;
}

.home-desktop .list-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.home-desktop .list-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  padding: 20px 0px;
  position: relative;
  width: 100%;
}

.home-desktop .frame-39 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.home-desktop .featured-icon-2 {
  background-color: var(--brandmain);
  border: 8px solid;
  border-color: #d6e84d8f;
  border-radius: 28px;
  height: 56px;
  margin-left: -4px;
  margin-top: -4px;
  position: relative;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-desktop .title-30 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: var(--mobile-extrabold-h2-font-family);
  font-size: var(--mobile-extrabold-h2-font-size);
  font-style: var(--mobile-extrabold-h2-font-style);
  font-weight: var(--mobile-extrabold-h2-font-weight);
  letter-spacing: var(--mobile-extrabold-h2-letter-spacing);
  line-height: var(--mobile-extrabold-h2-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .vector-3 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 764px;
  max-width: 1440px;
}

.home-desktop .section-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  justify-content: center;

  padding: 56px 0px;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: fit-content;
}

.home-desktop .container-5 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  width: 100%;
  position: relative;
}

.home-desktop .frame-40 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
}

.home-desktop .card-3 {
  align-items: center;
  background-color: #fbfcef;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  position: relative;
  max-width: 361px;
}

.home-desktop .avatar-8 {
  background-image: url(../../../static/img/avatar-1.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .title-31 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--greygrey-5);
  display: -webkit-box;
  font-family: 'Roboto', Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-desktop .title-32 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  color: var(--greygrey-5);
  display: -webkit-box;
  font-family: var(--desktop-medium-h5-font-family);
  font-size: var(--desktop-medium-h5-font-size);
  font-style: var(--desktop-medium-h5-font-style);
  font-weight: var(--desktop-medium-h5-font-weight);
  height: 72px;
  letter-spacing: var(--desktop-medium-h5-letter-spacing);
  line-height: var(--desktop-medium-h5-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.home-desktop .avatar-9 {
  background-image: url(../../../static/img/avatar-2.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-10 {
  background-image: url(../../../static/img/avatar-3.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-11 {
  background-image: url(../../../static/img/avatar-4.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .frame-41 {
  height: 9.81px;
  position: relative;
  width: 36.26px;
}

.home-desktop .card-4 {
  align-items: center;
  background-color: #fbfcef;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  position: relative;
  max-width: 362px;
}

.home-desktop .avatar-12 {
  background-image: url(../../../static/img/avatar-5.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-13 {
  background-image: url(../../../static/img/avatar-6.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .avatar-14 {
  background-image: url(../../../static/img/avatar-7.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  position: relative;
  width: 32px;
}

.home-desktop .vector-4 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 512px;
  max-width: 1440px;
}

.home-desktop .section-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  /* flex: 0 0 auto; */
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.home-desktop .subtitle-10 {
  color: var(--greygrey-5);
  font-family: 'Roboto', Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 64px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 745px;
}

.home-desktop .frame-42 {
  align-items: center;
  /* align-self: stretch; */
  display: flex;
  gap: 60px;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.home-desktop .US-divided-2 {
  position: relative;
  width: 50%;
}

.home-desktop .content-5 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 40%;
}

.home-desktop .content-6 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  margin-left: -3.17px;
  margin-right: -3.17px;
  position: relative;
  width: 100%;
}

.home-desktop .form {
  width: 100%;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.home-desktop .form-fields-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.home-desktop .div-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .content-7 {
  background: none;
  border: 0;
  color: #565656;
  flex: 1;
  flex-grow: 1;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.home-desktop .actions {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .home-desktop .frame-42 {
    gap: 10px;
  }

  .home-desktop .content-6 {
    align-items: flex-end;
  }

  .home-desktop .div-8 {
    gap: 10px;
  }
}

.home-desktop .button-base-5 {
  align-items: center;
  background-color: var(--brandmain);
  border: 1px solid;
  border-color: var(--brandmain);
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  cursor: pointer;
}

.home-desktop .text-7 {
  color: var(--greygrey-5);
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-desktop .vector-5 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 792px;
  width: 100%;
  max-width: 1440px;
}

.home-desktop .section-12 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  justify-content: center;

  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 60px 16px;
}

.home-desktop .container-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .content-8 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--brandmain);
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  padding: 64px;
  position: relative;
  width: 100%;
}

.home-desktop .heading-and {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.home-desktop .heading-2 {
  align-self: stretch;
  color: var(--greygrey-5);
  font-family: var(--desktop-extrabold-h2-font-family);
  font-size: var(--desktop-extrabold-h2-font-size);
  font-style: var(--desktop-extrabold-h2-font-style);
  font-weight: var(--desktop-extrabold-h2-font-weight);
  letter-spacing: var(--desktop-extrabold-h2-letter-spacing);
  line-height: var(--desktop-extrabold-h2-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .supporting-text-3 {
  align-self: stretch;
  color: #646465;
  font-family: var(--text-xl-normal-font-family);
  font-size: var(--text-xl-normal-font-size);
  font-style: var(--text-xl-normal-font-style);
  font-weight: var(--text-xl-normal-font-weight);
  letter-spacing: var(--text-xl-normal-letter-spacing);
  line-height: var(--text-xl-normal-line-height);
  position: relative;
}

.home-desktop .input-field-base-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
}

.home-desktop .button-6 {
  all: unset;
  align-items: flex-start;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home-desktop .button-base-6 {
  all: unset;
  align-items: center;
  background-color: var(--greygrey-5);
  border: 1px solid;
  border-color: var(--greygrey-5);
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  cursor: pointer;
}

.home-desktop .vector-6 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 332px;
  max-width: 1440px;
  display: none;
}

.home-desktop .footer-2 {
  align-items: center;
  background-color: var(--greygrey-5);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  padding: 64px 0px 48px;
  position: relative;
  width: 100%;
}

.home-desktop .container-7 {
  padding: 0px 16px;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.home-desktop .logo-and-links {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.home-desktop .supporting-text-4 {
  align-self: stretch;
  color: #828282;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  position: relative;
}

.home-desktop .text-8 {
  color: var(--brandmain);
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.home-desktop .text-9 {
  color: #828282;
  font-family: var(--text-md-medium-font-family);
  font-size: var(--text-md-medium-font-size);
  font-style: var(--text-md-medium-font-style);
  font-weight: var(--text-md-medium-font-weight);
  letter-spacing: var(--text-md-medium-letter-spacing);
  line-height: var(--text-md-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.home-desktop .frame-43 {
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.home-desktop .social-icon-2 {
  background-image: url(../../../static/img/subtract.svg);
  background-size: 100% 100%;
  height: 24px;
  position: relative;
  width: 24px;
}

.home-desktop .seconday-4 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  position: relative;
  width: 160px;
}

.home-desktop .container-8 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 0px 32px;
  position: relative;
  max-width: 1200px;
}

.home-desktop .content-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.home-desktop .footer-text-2 {
  color: #6f6f6f;
  flex: 1;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  margin-top: -1px;
  position: relative;
}

.home-desktop .footer-links-2 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.home-desktop .footer-link-3 {
  color: #6f6f6f;
  font-family: var(--text-md-normal-font-family);
  font-size: var(--text-md-normal-font-size);
  font-style: var(--text-md-normal-font-style);
  font-weight: var(--text-md-normal-font-weight);
  letter-spacing: var(--text-md-normal-letter-spacing);
  line-height: var(--text-md-normal-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.button-base-5:hover,
[class^='primary-']:hover {
  cursor: pointer;
  background-color: var(--brandbrand-3);
}

[class^='seconday-']:hover {
  cursor: pointer;
  background-color: var(--brandbrand-3);
}
[class^='primary']:hover {
  cursor: pointer;
  background-color: var(--brandbrand-3);
}

[class^='seconday']:hover {
  cursor: pointer;
  background-color: var(--brandbrand-3);
}

[class^='input-']:hover {
  cursor: text;
}

[class^='checkbox-']:hover {
  cursor: pointer;
}

textarea:focus-visible,
input:focus-visible {
  outline: none;
}

textarea {
  resize: none;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 350px) {
  .home-desktop .union-2 {
    max-width: 365px;
    width: 100%;
  }

  .home-desktop .untitled {
    max-width: 375px;
    width: 100%;
  }

  .home-desktop .union-3 {
    max-width: 369px;
    width: 100%;
  }

  .home-desktop .frame-8 {
    padding: 0px;
  }

  .home-desktop .subtitle-2 {
    max-width: 314px;
    width: 100%;
    padding: 0 20px;
  }

  .home-desktop .subtitle-4 {
    max-width: 224px;
    width: 100%;
  }

  .home-desktop .subtitle-5 {
    max-width: 222px;
    width: 100%;
  }

  .home-desktop .content-2 {
    padding: 12px;
  }

  .home-desktop .social-icons {
    gap: 0;
    /* gap: 10px; */
    width: 100%;
    justify-content: space-between;
  }

  .home-desktop .frame-3 {
    padding: 0 10px;
  }

  .home-desktop .content-3 {
    width: 100%;
    font-size: 12px;
  }

  .home-desktop .frame-21 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .home-desktop .text-wrapper-6 {
    position: static;
  }

  .home-desktop .text-wrapper-7 {
    position: static;
  }

  .home-desktop .overlap-group-3 {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    position: static;
  }

  .home-desktop .union-5 {
    position: static;
    width: 100%;
  }

  .home-desktop .div-7 {
    gap: 8px;
  }
}

.slick-track {
  display: flex !important;
  gap: 24px !important;
}

@media only screen and (max-width: 480px) {
  .slick-track {
    gap: 0 !important;
  }
}
