.privacy-page {
  width: 100%;
}

.privacy-page .main-banner {
  width: 100%;
  background: var(--Gray-50, #f9fafb);
}

.privacy-page .main-banner .container-main {
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.privacy-page .title-banner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.privacy-page .sub-title {
  color: var(--Brand-Brand--2, #9aac16);
  text-align: center;
  /* Text md/Semibold */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.privacy-page .main-title {
  color: var(--Gray-900, #101828);
  text-align: center;
  /* Desktop/ExtraBold/H1 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.privacy-page .desc-title {
  color: var(--Gray-500, #667085);
  text-align: center;
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 768px;
}

.privacy-page .main-content {
  width: 100%;
  max-width: 1440px;
  padding: 96px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}

.privacy-page p.desc-content {
  color: var(--Gray-500, #667085);
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 720px;
}

.privacy-page .privacy-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 720px;
}

.privacy-page h1.title {
  color: var(--Gray-900, #101828);
  /* Desktop/ExtraBold/H2 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.privacy-page h1.title.smaill-size {
  color: var(--Gray-900, #101828);
  /* Display xs/Semibold */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.privacy-page .desc {
  color: var(--Gray-500, #667085);
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 899px) {
  .privacy-page {
    width: 100%;
  }

  .privacy-page .main-banner .container-main {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .privacy-page .main-banner {
    padding: 52px 16px;
  }

  .privacy-page .sub-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .privacy-page .main-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .privacy-page .desc-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .privacy-page .main-content {
    width: 100%;
    max-width: 1440px;
    padding: 64px 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  .privacy-page p.desc-content {
    width: 100%;
  }

  .privacy-page .privacy-container {
    width: 100%;
  }

  .privacy-page .section-8 {
    display: none;
  }
}
