.mobile-menu-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 51px;
  background: white;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 32px;
  border-top: 1px solid #e6e6e6;
  margin-top: 8px;
}

.mobile-menu-container .mobile-menu-item {
  font-family: var(--mobile-extrabold-h1-font-family);
  cursor: pointer;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 700;
}
