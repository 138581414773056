.term-page {
  width: 100%;
}

.term-page .main-banner {
  width: 100%;
  background: var(--Gray-50, #f9fafb);
}

.term-page .main-banner .container-main {
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.term-page .title-banner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.term-page .sub-title {
  color: var(--Primary-600, #338dcd);
  text-align: center;
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.term-page .main-title {
  color: var(--Gray-900, #101828);
  text-align: center;
  /* Desktop/ExtraBold/H1 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.term-page .desc-title {
  color: var(--Gray-500, #667085);
  text-align: center;
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 768px;
}

.term-page .list-button {
  margin-top: 16px;
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-100, #f2f4f7);
  background: var(--Gray-100, #f2f4f7);
}

.term-page .default-btn {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--Base-White, #fff);
  cursor: pointer;

  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.term-page .secondary-btn {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
}
.term-page .main-content {
  width: 100%;
  max-width: 1440px;
  padding: 96px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}

.term-page p.desc-content {
  color: var(--Gray-500, #667085);
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 720px;
}

.term-page .privacy-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 720px;
}

.term-page h1.title {
  color: var(--Gray-900, #101828);
  /* Desktop/ExtraBold/H2 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.term-page h1.title.smaill-size {
  color: var(--Gray-900, #101828);
  /* Display xs/Semibold */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.term-page .desc {
  color: var(--Gray-500, #667085);
  /* Desktop/Medium/H4 */
  font-family: var(--desktop-extrabold-h1-font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.term-page .mobile-main-banner {
  width: 100%;
  padding: 64px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--Primary-800, #005490);
  gap: 16px;
}

.term-page .mobile-title-banner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.term-page .mobile-sub-title {
  color: var(--Primary-200, #bddaef);
  text-align: center;
  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.term-page .mobile-main-title {
  color: var(--Base-White, #fff);
  text-align: center;
  /* Display md/Semibold */
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.term-page .mobile-desc-title {
  color: var(--Primary-200, #bddaef);
  text-align: center;
  /* Text lg/Normal */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.term-page .mobile-list-button {
  margin-top: 16px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Primary-100, #cee3f3);
}

.term-page .mobile-default-btn {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--Base-White, #fff);
  cursor: pointer;

  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.term-page .mobile-secondary-btn {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .term-page {
    width: 100%;
  }

  .term-page .main-banner {
    padding: 52px 32px;
  }

  .term-page .sub-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .main-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .desc-title {
    font-family: var(--desktop-extrabold-h1-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .term-page .main-content {
    width: 100%;
    max-width: 1440px;
    padding: 64px 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  .term-page p.desc-content {
    width: 100%;
  }

  .term-page .privacy-container {
    width: 100%;
  }

  .term-page .section-12 {
    padding: 60px 16px;
  }
  .term-page content-6 {
    padding: 0;
  }
  .term-page .container-6 {
    padding: 0;
  }
  .term-page .content-3 {
    text-align: center;
  }
  .term-page .content-8 {
    flex-direction: column;
    padding: 24px;
    align-items: center;
    gap: 16px;
  }
  .term-page .heading-and {
    width: 100%;
    text-align: center;
  }
  .term-page .heading-2 {
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .term-page .supporting-text-3 {
    color: var(--Grey-Grey--2, #636366);
    /* Mobile/Medium/H4 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .term-page .div-7 {
    width: 100%;
    flex-direction: column;
  }

  .term-page .input-field-base-wrapper {
    max-width: 100%;
    width: 100%;
  }
  .term-page .hint-text {
    text-align: center;
  }
  .term-page .button-6 {
    width: 100%;
  }
  .term-page .button-base-6 {
    width: 100%;
  }
}
